/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactDto } from '../models';
// @ts-ignore
import { ContactPageDto } from '../models';
// @ts-ignore
import { ContactSearchResultPageDto } from '../models';
// @ts-ignore
import { CreateNoteDto } from '../models';
// @ts-ignore
import { NoteDto } from '../models';
// @ts-ignore
import { NotePageDto } from '../models';
// @ts-ignore
import { SearchRequestDto } from '../models';
/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * This is a very performant method for checking if a Contact exists.
         * @summary Check if Contact exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contactExists.');
            }
            const localVarPath = `/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactDto} contact 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: async (contact: ContactDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact' is not null or undefined
            if (contact === null || contact === undefined) {
                throw new RequiredError('contact','Required parameter contact was null or undefined when calling createContact.');
            }
            const localVarPath = `/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contact !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contact !== undefined ? contact : {})
                : (contact || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a note with the target set to the id of the contact.
         * @param {string} id 
         * @param {CreateNoteDto} createNote 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactNote: async (id: string, createNote: CreateNoteDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createContactNote.');
            }
            // verify required parameter 'createNote' is not null or undefined
            if (createNote === null || createNote === undefined) {
                throw new RequiredError('createNote','Required parameter createNote was null or undefined when calling createContactNote.');
            }
            const localVarPath = `/contacts/{id}/notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createNote !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createNote !== undefined ? createNote : {})
                : (createNote || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteContact.');
            }
            const localVarPath = `/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyContacts: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyContacts.');
            }
            const localVarPath = `/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getContact.');
            }
            const localVarPath = `/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all personal and public notes for a contact
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactNotes: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getContactNotes.');
            }
            const localVarPath = `/contacts/{id}/notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactsCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contacts/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all contacts that have publicAccess set to true
         * @summary Public Contacts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicContacts: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contacts/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} contact 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContact: async (id: string, contact: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchContact.');
            }
            // verify required parameter 'contact' is not null or undefined
            if (contact === null || contact === undefined) {
                throw new RequiredError('contact','Required parameter contact was null or undefined when calling patchContact.');
            }
            const localVarPath = `/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contact !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contact !== undefined ? contact : {})
                : (contact || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performContactSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performContactSearch.');
            }
            const localVarPath = `/contacts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ContactDto} contact 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContact: async (id: string, contact: ContactDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putContact.');
            }
            // verify required parameter 'contact' is not null or undefined
            if (contact === null || contact === undefined) {
                throw new RequiredError('contact','Required parameter contact was null or undefined when calling putContact.');
            }
            const localVarPath = `/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contact !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contact !== undefined ? contact : {})
                : (contact || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncMicrosoftContacts: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contacts/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * This is a very performant method for checking if a Contact exists.
         * @summary Check if Contact exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).contactExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ContactDto} contact 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContact(contact: ContactDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDto>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).createContact(contact, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates a note with the target set to the id of the contact.
         * @param {string} id 
         * @param {CreateNoteDto} createNote 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContactNote(id: string, createNote: CreateNoteDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteDto>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).createContactNote(id, createNote, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContact(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).deleteContact(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyContacts(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).deleteManyContacts(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContact(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDto>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).getContact(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gets all personal and public notes for a contact
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactNotes(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotePageDto>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).getContactNotes(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContacts(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactPageDto>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).getContacts(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).getContactsCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns all contacts that have publicAccess set to true
         * @summary Public Contacts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicContacts(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactPageDto>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).getPublicContacts(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} contact 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchContact(id: string, contact: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDto>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).patchContact(id, contact, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performContactSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactSearchResultPageDto>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).performContactSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ContactDto} contact 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContact(id: string, contact: ContactDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDto>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).putContact(id, contact, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncMicrosoftContacts(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await ContactsApiAxiosParamCreator(axiosClientConfiguration).syncMicrosoftContacts(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * This is a very performant method for checking if a Contact exists.
         * @summary Check if Contact exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactExists(id: string, options?: any): AxiosPromise<void> {
            return ContactsApiFp(axiosClientConfiguration).contactExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactDto} contact 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(contact: ContactDto, translate?: string, options?: any): AxiosPromise<ContactDto> {
            return ContactsApiFp(axiosClientConfiguration).createContact(contact, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a note with the target set to the id of the contact.
         * @param {string} id 
         * @param {CreateNoteDto} createNote 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactNote(id: string, createNote: CreateNoteDto, translate?: string, options?: any): AxiosPromise<NoteDto> {
            return ContactsApiFp(axiosClientConfiguration).createContactNote(id, createNote, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return ContactsApiFp(axiosClientConfiguration).deleteContact(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyContacts(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return ContactsApiFp(axiosClientConfiguration).deleteManyContacts(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact(id: string, translate?: string, options?: any): AxiosPromise<ContactDto> {
            return ContactsApiFp(axiosClientConfiguration).getContact(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all personal and public notes for a contact
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactNotes(id: string, translate?: string, options?: any): AxiosPromise<NotePageDto> {
            return ContactsApiFp(axiosClientConfiguration).getContactNotes(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<ContactPageDto> {
            return ContactsApiFp(axiosClientConfiguration).getContacts(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return ContactsApiFp(axiosClientConfiguration).getContactsCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all contacts that have publicAccess set to true
         * @summary Public Contacts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicContacts(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<ContactPageDto> {
            return ContactsApiFp(axiosClientConfiguration).getPublicContacts(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} contact 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContact(id: string, contact: any, translate?: string, options?: any): AxiosPromise<ContactDto> {
            return ContactsApiFp(axiosClientConfiguration).patchContact(id, contact, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performContactSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<ContactSearchResultPageDto> {
            return ContactsApiFp(axiosClientConfiguration).performContactSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ContactDto} contact 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContact(id: string, contact: ContactDto, translate?: string, options?: any): AxiosPromise<ContactDto> {
            return ContactsApiFp(axiosClientConfiguration).putContact(id, contact, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncMicrosoftContacts(translate?: string, options?: any): AxiosPromise<number> {
            return ContactsApiFp(axiosClientConfiguration).syncMicrosoftContacts(translate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for contactExists operation in ContactsApi.
 * @export
 * @interface ContactsApiContactExistsRequest
 */
export interface ContactsApiContactExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiContactExists
     */
    readonly id: string
}

/**
 * Request parameters for createContact operation in ContactsApi.
 * @export
 * @interface ContactsApiCreateContactRequest
 */
export interface ContactsApiCreateContactRequest {
    /**
     * 
     * @type {ContactDto}
     * @memberof ContactsApiCreateContact
     */
    readonly contact: ContactDto

    /**
     * 
     * @type {string}
     * @memberof ContactsApiCreateContact
     */
    readonly translate?: string
}

/**
 * Request parameters for createContactNote operation in ContactsApi.
 * @export
 * @interface ContactsApiCreateContactNoteRequest
 */
export interface ContactsApiCreateContactNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiCreateContactNote
     */
    readonly id: string

    /**
     * 
     * @type {CreateNoteDto}
     * @memberof ContactsApiCreateContactNote
     */
    readonly createNote: CreateNoteDto

    /**
     * 
     * @type {string}
     * @memberof ContactsApiCreateContactNote
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteContact operation in ContactsApi.
 * @export
 * @interface ContactsApiDeleteContactRequest
 */
export interface ContactsApiDeleteContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiDeleteContact
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ContactsApiDeleteContact
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyContacts operation in ContactsApi.
 * @export
 * @interface ContactsApiDeleteManyContactsRequest
 */
export interface ContactsApiDeleteManyContactsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactsApiDeleteManyContacts
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ContactsApiDeleteManyContacts
     */
    readonly translate?: string
}

/**
 * Request parameters for getContact operation in ContactsApi.
 * @export
 * @interface ContactsApiGetContactRequest
 */
export interface ContactsApiGetContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContact
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContact
     */
    readonly translate?: string
}

/**
 * Request parameters for getContactNotes operation in ContactsApi.
 * @export
 * @interface ContactsApiGetContactNotesRequest
 */
export interface ContactsApiGetContactNotesRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContactNotes
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContactNotes
     */
    readonly translate?: string
}

/**
 * Request parameters for getContacts operation in ContactsApi.
 * @export
 * @interface ContactsApiGetContactsRequest
 */
export interface ContactsApiGetContactsRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactsApiGetContacts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ContactsApiGetContacts
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContacts
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof ContactsApiGetContacts
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContacts
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContacts
     */
    readonly search?: string
}

/**
 * Request parameters for getContactsCount operation in ContactsApi.
 * @export
 * @interface ContactsApiGetContactsCountRequest
 */
export interface ContactsApiGetContactsCountRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactsApiGetContactsCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ContactsApiGetContactsCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContactsCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContactsCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof ContactsApiGetContactsCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContactsCount
     */
    readonly translate?: string
}

/**
 * Request parameters for getPublicContacts operation in ContactsApi.
 * @export
 * @interface ContactsApiGetPublicContactsRequest
 */
export interface ContactsApiGetPublicContactsRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactsApiGetPublicContacts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ContactsApiGetPublicContacts
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetPublicContacts
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetPublicContacts
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof ContactsApiGetPublicContacts
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetPublicContacts
     */
    readonly translate?: string
}

/**
 * Request parameters for patchContact operation in ContactsApi.
 * @export
 * @interface ContactsApiPatchContactRequest
 */
export interface ContactsApiPatchContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiPatchContact
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof ContactsApiPatchContact
     */
    readonly contact: any

    /**
     * 
     * @type {string}
     * @memberof ContactsApiPatchContact
     */
    readonly translate?: string
}

/**
 * Request parameters for performContactSearch operation in ContactsApi.
 * @export
 * @interface ContactsApiPerformContactSearchRequest
 */
export interface ContactsApiPerformContactSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof ContactsApiPerformContactSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof ContactsApiPerformContactSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for putContact operation in ContactsApi.
 * @export
 * @interface ContactsApiPutContactRequest
 */
export interface ContactsApiPutContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiPutContact
     */
    readonly id: string

    /**
     * 
     * @type {ContactDto}
     * @memberof ContactsApiPutContact
     */
    readonly contact: ContactDto

    /**
     * 
     * @type {string}
     * @memberof ContactsApiPutContact
     */
    readonly translate?: string
}

/**
 * Request parameters for syncMicrosoftContacts operation in ContactsApi.
 * @export
 * @interface ContactsApiSyncMicrosoftContactsRequest
 */
export interface ContactsApiSyncMicrosoftContactsRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiSyncMicrosoftContacts
     */
    readonly translate?: string
}

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
    /**
     * This is a very performant method for checking if a Contact exists.
     * @summary Check if Contact exists
     * @param {ContactsApiContactExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public contactExists(requestParameters: ContactsApiContactExistsRequest, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).contactExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactsApiCreateContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public createContact(requestParameters: ContactsApiCreateContactRequest, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).createContact(requestParameters.contact, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a note with the target set to the id of the contact.
     * @param {ContactsApiCreateContactNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public createContactNote(requestParameters: ContactsApiCreateContactNoteRequest, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).createContactNote(requestParameters.id, requestParameters.createNote, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactsApiDeleteContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public deleteContact(requestParameters: ContactsApiDeleteContactRequest, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).deleteContact(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactsApiDeleteManyContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public deleteManyContacts(requestParameters: ContactsApiDeleteManyContactsRequest, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).deleteManyContacts(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactsApiGetContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public getContact(requestParameters: ContactsApiGetContactRequest, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).getContact(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all personal and public notes for a contact
     * @param {ContactsApiGetContactNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public getContactNotes(requestParameters: ContactsApiGetContactNotesRequest, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).getContactNotes(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactsApiGetContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public getContacts(requestParameters: ContactsApiGetContactsRequest = {}, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).getContacts(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactsApiGetContactsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public getContactsCount(requestParameters: ContactsApiGetContactsCountRequest = {}, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).getContactsCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all contacts that have publicAccess set to true
     * @summary Public Contacts
     * @param {ContactsApiGetPublicContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public getPublicContacts(requestParameters: ContactsApiGetPublicContactsRequest = {}, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).getPublicContacts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactsApiPatchContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public patchContact(requestParameters: ContactsApiPatchContactRequest, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).patchContact(requestParameters.id, requestParameters.contact, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactsApiPerformContactSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public performContactSearch(requestParameters: ContactsApiPerformContactSearchRequest, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).performContactSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactsApiPutContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public putContact(requestParameters: ContactsApiPutContactRequest, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).putContact(requestParameters.id, requestParameters.contact, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactsApiSyncMicrosoftContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public syncMicrosoftContacts(requestParameters: ContactsApiSyncMicrosoftContactsRequest = {}, options?: any) {
        return ContactsApiFp(this.axiosClientConfiguration).syncMicrosoftContacts(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }
}
