/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactCustomFieldDto } from '../models';
// @ts-ignore
import { ContactCustomFieldPageDto } from '../models';
// @ts-ignore
import { ContactCustomFieldSearchResultPageDto } from '../models';
// @ts-ignore
import { SearchRequestDto } from '../models';
/**
 * ContactCustomFieldsApi - axios parameter creator
 * @export
 */
export const ContactCustomFieldsApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * This is a very performant method for checking if a ContactCustomField exists.
         * @summary Check if ContactCustomField exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactCustomFieldExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contactCustomFieldExists.');
            }
            const localVarPath = `/contactcustomfields/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactCustomFieldDto} contactCustomField 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactCustomField: async (contactCustomField: ContactCustomFieldDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactCustomField' is not null or undefined
            if (contactCustomField === null || contactCustomField === undefined) {
                throw new RequiredError('contactCustomField','Required parameter contactCustomField was null or undefined when calling createContactCustomField.');
            }
            const localVarPath = `/contactcustomfields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contactCustomField !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contactCustomField !== undefined ? contactCustomField : {})
                : (contactCustomField || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactCustomField: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteContactCustomField.');
            }
            const localVarPath = `/contactcustomfields/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyContactCustomFields: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyContactCustomFields.');
            }
            const localVarPath = `/contactcustomfields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactCustomField: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getContactCustomField.');
            }
            const localVarPath = `/contactcustomfields/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactCustomFields: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contactcustomfields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactCustomFieldsCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contactcustomfields/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} contactCustomField 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContactCustomField: async (id: string, contactCustomField: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchContactCustomField.');
            }
            // verify required parameter 'contactCustomField' is not null or undefined
            if (contactCustomField === null || contactCustomField === undefined) {
                throw new RequiredError('contactCustomField','Required parameter contactCustomField was null or undefined when calling patchContactCustomField.');
            }
            const localVarPath = `/contactcustomfields/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contactCustomField !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contactCustomField !== undefined ? contactCustomField : {})
                : (contactCustomField || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performContactCustomFieldSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performContactCustomFieldSearch.');
            }
            const localVarPath = `/contactcustomfields/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ContactCustomFieldDto} contactCustomField 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContactCustomField: async (id: string, contactCustomField: ContactCustomFieldDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putContactCustomField.');
            }
            // verify required parameter 'contactCustomField' is not null or undefined
            if (contactCustomField === null || contactCustomField === undefined) {
                throw new RequiredError('contactCustomField','Required parameter contactCustomField was null or undefined when calling putContactCustomField.');
            }
            const localVarPath = `/contactcustomfields/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contactCustomField !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contactCustomField !== undefined ? contactCustomField : {})
                : (contactCustomField || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactCustomFieldsApi - functional programming interface
 * @export
 */
export const ContactCustomFieldsApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * This is a very performant method for checking if a ContactCustomField exists.
         * @summary Check if ContactCustomField exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactCustomFieldExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContactCustomFieldsApiAxiosParamCreator(axiosClientConfiguration).contactCustomFieldExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ContactCustomFieldDto} contactCustomField 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContactCustomField(contactCustomField: ContactCustomFieldDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactCustomFieldDto>> {
            const localVarAxiosArgs = await ContactCustomFieldsApiAxiosParamCreator(axiosClientConfiguration).createContactCustomField(contactCustomField, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContactCustomField(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContactCustomFieldsApiAxiosParamCreator(axiosClientConfiguration).deleteContactCustomField(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyContactCustomFields(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContactCustomFieldsApiAxiosParamCreator(axiosClientConfiguration).deleteManyContactCustomFields(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactCustomField(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactCustomFieldDto>> {
            const localVarAxiosArgs = await ContactCustomFieldsApiAxiosParamCreator(axiosClientConfiguration).getContactCustomField(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactCustomFields(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactCustomFieldPageDto>> {
            const localVarAxiosArgs = await ContactCustomFieldsApiAxiosParamCreator(axiosClientConfiguration).getContactCustomFields(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactCustomFieldsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await ContactCustomFieldsApiAxiosParamCreator(axiosClientConfiguration).getContactCustomFieldsCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} contactCustomField 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchContactCustomField(id: string, contactCustomField: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactCustomFieldDto>> {
            const localVarAxiosArgs = await ContactCustomFieldsApiAxiosParamCreator(axiosClientConfiguration).patchContactCustomField(id, contactCustomField, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performContactCustomFieldSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactCustomFieldSearchResultPageDto>> {
            const localVarAxiosArgs = await ContactCustomFieldsApiAxiosParamCreator(axiosClientConfiguration).performContactCustomFieldSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ContactCustomFieldDto} contactCustomField 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContactCustomField(id: string, contactCustomField: ContactCustomFieldDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactCustomFieldDto>> {
            const localVarAxiosArgs = await ContactCustomFieldsApiAxiosParamCreator(axiosClientConfiguration).putContactCustomField(id, contactCustomField, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContactCustomFieldsApi - factory interface
 * @export
 */
export const ContactCustomFieldsApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * This is a very performant method for checking if a ContactCustomField exists.
         * @summary Check if ContactCustomField exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactCustomFieldExists(id: string, options?: any): AxiosPromise<void> {
            return ContactCustomFieldsApiFp(axiosClientConfiguration).contactCustomFieldExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactCustomFieldDto} contactCustomField 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactCustomField(contactCustomField: ContactCustomFieldDto, translate?: string, options?: any): AxiosPromise<ContactCustomFieldDto> {
            return ContactCustomFieldsApiFp(axiosClientConfiguration).createContactCustomField(contactCustomField, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactCustomField(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return ContactCustomFieldsApiFp(axiosClientConfiguration).deleteContactCustomField(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyContactCustomFields(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return ContactCustomFieldsApiFp(axiosClientConfiguration).deleteManyContactCustomFields(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactCustomField(id: string, translate?: string, options?: any): AxiosPromise<ContactCustomFieldDto> {
            return ContactCustomFieldsApiFp(axiosClientConfiguration).getContactCustomField(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactCustomFields(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<ContactCustomFieldPageDto> {
            return ContactCustomFieldsApiFp(axiosClientConfiguration).getContactCustomFields(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactCustomFieldsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return ContactCustomFieldsApiFp(axiosClientConfiguration).getContactCustomFieldsCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} contactCustomField 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContactCustomField(id: string, contactCustomField: any, translate?: string, options?: any): AxiosPromise<ContactCustomFieldDto> {
            return ContactCustomFieldsApiFp(axiosClientConfiguration).patchContactCustomField(id, contactCustomField, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performContactCustomFieldSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<ContactCustomFieldSearchResultPageDto> {
            return ContactCustomFieldsApiFp(axiosClientConfiguration).performContactCustomFieldSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ContactCustomFieldDto} contactCustomField 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContactCustomField(id: string, contactCustomField: ContactCustomFieldDto, translate?: string, options?: any): AxiosPromise<ContactCustomFieldDto> {
            return ContactCustomFieldsApiFp(axiosClientConfiguration).putContactCustomField(id, contactCustomField, translate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for contactCustomFieldExists operation in ContactCustomFieldsApi.
 * @export
 * @interface ContactCustomFieldsApiContactCustomFieldExistsRequest
 */
export interface ContactCustomFieldsApiContactCustomFieldExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiContactCustomFieldExists
     */
    readonly id: string
}

/**
 * Request parameters for createContactCustomField operation in ContactCustomFieldsApi.
 * @export
 * @interface ContactCustomFieldsApiCreateContactCustomFieldRequest
 */
export interface ContactCustomFieldsApiCreateContactCustomFieldRequest {
    /**
     * 
     * @type {ContactCustomFieldDto}
     * @memberof ContactCustomFieldsApiCreateContactCustomField
     */
    readonly contactCustomField: ContactCustomFieldDto

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiCreateContactCustomField
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteContactCustomField operation in ContactCustomFieldsApi.
 * @export
 * @interface ContactCustomFieldsApiDeleteContactCustomFieldRequest
 */
export interface ContactCustomFieldsApiDeleteContactCustomFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiDeleteContactCustomField
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiDeleteContactCustomField
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyContactCustomFields operation in ContactCustomFieldsApi.
 * @export
 * @interface ContactCustomFieldsApiDeleteManyContactCustomFieldsRequest
 */
export interface ContactCustomFieldsApiDeleteManyContactCustomFieldsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactCustomFieldsApiDeleteManyContactCustomFields
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiDeleteManyContactCustomFields
     */
    readonly translate?: string
}

/**
 * Request parameters for getContactCustomField operation in ContactCustomFieldsApi.
 * @export
 * @interface ContactCustomFieldsApiGetContactCustomFieldRequest
 */
export interface ContactCustomFieldsApiGetContactCustomFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiGetContactCustomField
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiGetContactCustomField
     */
    readonly translate?: string
}

/**
 * Request parameters for getContactCustomFields operation in ContactCustomFieldsApi.
 * @export
 * @interface ContactCustomFieldsApiGetContactCustomFieldsRequest
 */
export interface ContactCustomFieldsApiGetContactCustomFieldsRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactCustomFieldsApiGetContactCustomFields
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ContactCustomFieldsApiGetContactCustomFields
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiGetContactCustomFields
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof ContactCustomFieldsApiGetContactCustomFields
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiGetContactCustomFields
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiGetContactCustomFields
     */
    readonly search?: string
}

/**
 * Request parameters for getContactCustomFieldsCount operation in ContactCustomFieldsApi.
 * @export
 * @interface ContactCustomFieldsApiGetContactCustomFieldsCountRequest
 */
export interface ContactCustomFieldsApiGetContactCustomFieldsCountRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactCustomFieldsApiGetContactCustomFieldsCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ContactCustomFieldsApiGetContactCustomFieldsCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiGetContactCustomFieldsCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiGetContactCustomFieldsCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof ContactCustomFieldsApiGetContactCustomFieldsCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiGetContactCustomFieldsCount
     */
    readonly translate?: string
}

/**
 * Request parameters for patchContactCustomField operation in ContactCustomFieldsApi.
 * @export
 * @interface ContactCustomFieldsApiPatchContactCustomFieldRequest
 */
export interface ContactCustomFieldsApiPatchContactCustomFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiPatchContactCustomField
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof ContactCustomFieldsApiPatchContactCustomField
     */
    readonly contactCustomField: any

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiPatchContactCustomField
     */
    readonly translate?: string
}

/**
 * Request parameters for performContactCustomFieldSearch operation in ContactCustomFieldsApi.
 * @export
 * @interface ContactCustomFieldsApiPerformContactCustomFieldSearchRequest
 */
export interface ContactCustomFieldsApiPerformContactCustomFieldSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof ContactCustomFieldsApiPerformContactCustomFieldSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiPerformContactCustomFieldSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for putContactCustomField operation in ContactCustomFieldsApi.
 * @export
 * @interface ContactCustomFieldsApiPutContactCustomFieldRequest
 */
export interface ContactCustomFieldsApiPutContactCustomFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiPutContactCustomField
     */
    readonly id: string

    /**
     * 
     * @type {ContactCustomFieldDto}
     * @memberof ContactCustomFieldsApiPutContactCustomField
     */
    readonly contactCustomField: ContactCustomFieldDto

    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldsApiPutContactCustomField
     */
    readonly translate?: string
}

/**
 * ContactCustomFieldsApi - object-oriented interface
 * @export
 * @class ContactCustomFieldsApi
 * @extends {BaseAPI}
 */
export class ContactCustomFieldsApi extends BaseAPI {
    /**
     * This is a very performant method for checking if a ContactCustomField exists.
     * @summary Check if ContactCustomField exists
     * @param {ContactCustomFieldsApiContactCustomFieldExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactCustomFieldsApi
     */
    public contactCustomFieldExists(requestParameters: ContactCustomFieldsApiContactCustomFieldExistsRequest, options?: any) {
        return ContactCustomFieldsApiFp(this.axiosClientConfiguration).contactCustomFieldExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCustomFieldsApiCreateContactCustomFieldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactCustomFieldsApi
     */
    public createContactCustomField(requestParameters: ContactCustomFieldsApiCreateContactCustomFieldRequest, options?: any) {
        return ContactCustomFieldsApiFp(this.axiosClientConfiguration).createContactCustomField(requestParameters.contactCustomField, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCustomFieldsApiDeleteContactCustomFieldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactCustomFieldsApi
     */
    public deleteContactCustomField(requestParameters: ContactCustomFieldsApiDeleteContactCustomFieldRequest, options?: any) {
        return ContactCustomFieldsApiFp(this.axiosClientConfiguration).deleteContactCustomField(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCustomFieldsApiDeleteManyContactCustomFieldsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactCustomFieldsApi
     */
    public deleteManyContactCustomFields(requestParameters: ContactCustomFieldsApiDeleteManyContactCustomFieldsRequest, options?: any) {
        return ContactCustomFieldsApiFp(this.axiosClientConfiguration).deleteManyContactCustomFields(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCustomFieldsApiGetContactCustomFieldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactCustomFieldsApi
     */
    public getContactCustomField(requestParameters: ContactCustomFieldsApiGetContactCustomFieldRequest, options?: any) {
        return ContactCustomFieldsApiFp(this.axiosClientConfiguration).getContactCustomField(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCustomFieldsApiGetContactCustomFieldsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactCustomFieldsApi
     */
    public getContactCustomFields(requestParameters: ContactCustomFieldsApiGetContactCustomFieldsRequest = {}, options?: any) {
        return ContactCustomFieldsApiFp(this.axiosClientConfiguration).getContactCustomFields(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCustomFieldsApiGetContactCustomFieldsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactCustomFieldsApi
     */
    public getContactCustomFieldsCount(requestParameters: ContactCustomFieldsApiGetContactCustomFieldsCountRequest = {}, options?: any) {
        return ContactCustomFieldsApiFp(this.axiosClientConfiguration).getContactCustomFieldsCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCustomFieldsApiPatchContactCustomFieldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactCustomFieldsApi
     */
    public patchContactCustomField(requestParameters: ContactCustomFieldsApiPatchContactCustomFieldRequest, options?: any) {
        return ContactCustomFieldsApiFp(this.axiosClientConfiguration).patchContactCustomField(requestParameters.id, requestParameters.contactCustomField, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCustomFieldsApiPerformContactCustomFieldSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactCustomFieldsApi
     */
    public performContactCustomFieldSearch(requestParameters: ContactCustomFieldsApiPerformContactCustomFieldSearchRequest, options?: any) {
        return ContactCustomFieldsApiFp(this.axiosClientConfiguration).performContactCustomFieldSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCustomFieldsApiPutContactCustomFieldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactCustomFieldsApi
     */
    public putContactCustomField(requestParameters: ContactCustomFieldsApiPutContactCustomFieldRequest, options?: any) {
        return ContactCustomFieldsApiFp(this.axiosClientConfiguration).putContactCustomField(requestParameters.id, requestParameters.contactCustomField, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }
}
